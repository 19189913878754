import {fetchServerApi} from "../fetch-api";
import ActionConfig from "../../models/action-config";
import _ from "lodash";

/**
 * @param id{number}
 * @return {Promise<ActionConfig>}
 */
export const fetchAction = async (id) => {
  const response = await fetchServerApi().get(`/settings/actions/${id}`);
  return ActionConfig.constructFromObject(response.data);
}

/**
 * @param id{number}
 * @param data{ActionConfig}
 * @return {Promise<ActionConfig>}
 */
export const updateAction = async (id, data) => {
  const update = ActionConfig.constructFromObject(data, null, true);
  const response = await fetchServerApi().put(`/settings/actions/${id}`, update);
  return ActionConfig.constructFromObject(response.data);

}

/**
 * @param data{ActionConfig}
 * @return {Promise<ActionConfig>}
 */
export const createAction = async (data) => {
  const update = ActionConfig.constructFromObject(data, null, true)
  const response = await fetchServerApi().post(`/settings/actions/`, update);
  return ActionConfig.constructFromObject(response.data);
}
