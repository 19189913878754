import {fetchServers} from "../serversAPI";

export const selectServerAction = (server) => (dispatch) => {
  dispatch({
    type: 'SELECT_SERVER',
    payload: server
  });
  localStorage.setItem('server', JSON.stringify(server));
};

export const getServersAction = (dispatch, getState) => {
  fetchServers()
    .then((data) => {
      dispatch({
        type: 'SERVER_LIST',
        payload: data
      });
      dispatch(selectServerAction(data[0]));
    })
    .catch(() => {
      dispatch(selectServerAction(null));
      dispatch({
        type: 'SERVER_LIST',
        payload: []
      });
    });
};
