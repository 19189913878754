import React, {useState} from 'react';
import {notification, Spin} from "antd";
import {createAction} from "./actionAPI";
import {Action} from "./Action";
import {useNavigate} from "react-router";
import ActionConfig from "../../models/action-config";

export const ActionNew = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(new ActionConfig());

  const onFinish = (updatedDada) => {
    setLoading(true);
    createAction(updatedDada)
      .then((data) => {
        navigate(`/actions/edit/${data._id}`);
      })
        .catch(error => {
          notification.error({
            message: 'Failed to create action',
            description: error.message,
          })
        })
      .finally(() => setLoading(false));
  };

  return <>
    {loading && <Spin spinning={true} tip="loading..." />}
    <Action data={data} onUpdate={onFinish} isCreate={true} />
  </>
}
