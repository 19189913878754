import {fetchServerApi} from "../fetch-api";
import { createAction } from "../action/actionAPI";

export const fetchActions = async () => {
  const result = await fetchServerApi().get('/settings/actions');
  return result.data;
}

export const deleteAction = async (id) => {
  await fetchServerApi().delete(`/settings/actions/${id}`);
}

export const duplicateAction = async (action) => {
  const data = {
    ...action,
  };

  Object.keys(data)
    .filter(name => name[0] === '_')
    .forEach(name => {
      delete data[name];
    });
  return createAction(data);
}
