import {
  modelConvertToBool,
  modelConvertToNum,
  modelConvertToStr
} from "../constants/constants";
import FtpSettings from "./ftp-settings";

/**
 * The DestinationFtpSettings model module.
 * @module model/DestinationFtpSettings
 * @version 1.0.0
 */
class DestinationFtpSettings extends FtpSettings {
  /**
   * Constructs a new <code>DestinationFtpSettings</code>.
   * @alias module:model/DestinationFtpSettings
   */
  constructor() {
    super();

    /**
     * @member {string} channelName
     * @default ''
     */
    this.channelName = '';

    /**
     * @member {boolean} deleteUnusedFiles
     * @default false
     */
    this.deleteUnusedFiles = false;

    /**
     * @member {number} keepFilesDays
     * @default 3
     */
    this.keepFilesDays = 3;
  }

  /**
   * Constructs a <code>ConfigEmail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DestinationFtpSettings} obj Optional instance to populate.
   * @param isUpdate
   * @return {DestinationFtpSettings} The populated <code>DestinationFtpSettings</code> instance.
   */
  static constructFromObject(data, obj = null, isUpdate = false) {
    obj = obj || new DestinationFtpSettings();
    obj = super.constructFromObject(data, obj, isUpdate);

    if (data) {
      if (data.hasOwnProperty('channelName')) {
        obj.channelName = modelConvertToStr(data.channelName);
      }

      if (data.hasOwnProperty('deleteUnusedFiles')) {
        obj.deleteUnusedFiles = modelConvertToBool(data.deleteUnusedFiles);
      }

      if (data.hasOwnProperty('keepFilesDays')) {
        obj.keepFilesDays = modelConvertToNum(data.keepFilesDays);
      }
    }

    return obj;
  }
}

export default DestinationFtpSettings;
