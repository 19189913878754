import axios from 'axios';

const token = localStorage.getItem('token');

const setLogoutInterceptor = (axios) => {
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    const url = error.request.responseURL;
    if (error.response.status === 401 && !url.includes('/api/auth/login')) {
      localStorage.removeItem('token');
      window.location.reload();
    }

    throw error;
  });
};

let client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
setLogoutInterceptor(client);

export const setToken = (token) => {
  client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
  setLogoutInterceptor(client);
};

if (token) {
  setToken(token);
}

export const fetchApi = () => {
  return client
};

export const fetchServerApi = () => {
  const server = localStorage.getItem('server');
  let serverAddress = '';
  try {
    serverAddress = JSON.parse(server)?.address;
  } catch (error) {

  }

  const result = axios.create({
    baseURL: serverAddress,
    validateStatus: (status) => status >= 200 && status < 300,
  })

  setLogoutInterceptor(result);
  return result
}
