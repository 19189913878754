import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Button, Empty, Modal, notification, Spin, Timeline} from "antd";
import {applyBackup, fetchBackups} from "./backupsAPI";

export const Backups = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState(null);

  const loadBackups = () => {
    setInitLoading(true);
    fetchBackups()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to fetch backups',
          description: error.message,
        })
        setData(null)
      })
      .finally(() => {
        setInitLoading(false);
      });
  };

  useEffect(() => {
    loadBackups();
  }, []);

  if (!data) {
    return <Empty />
  }

  const onRestore = (fileId) => {
    setInitLoading(true);
    applyBackup(fileId)
      .then(() => {
        loadBackups();
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to restore backup',
          description: error.message,
        })
      })
      .finally(() => setInitLoading(false));
  };

  const onRestoreModal = (value) => {
    Modal.confirm({
      title: 'Are you sure restore backup?',
      content: `Backup from: ${moment(value, 'x').format('YYYY-MM-DD HH:mm:ss')}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onRestore(value);
      },
    });
  };

  return <>
    <Spin spinning={initLoading} tip="loading...">
      <Timeline>
        {(data || []).map(fileId => {
          return <Timeline.Item>
            {moment(fileId, 'x').format('YYYY-MM-DD HH:mm:ss')}
            {<Button onClick={() => onRestoreModal(fileId)} type="link">Restore</Button>}
          </Timeline.Item>
        })}
      </Timeline>
    </Spin>
  </>;
}
