import {fetchServerApi} from "../fetch-api";

export const fetchGeneral = async (address) => {
  const result = await fetchServerApi(address).get('/settings/global');
  return result.data;
}

export const updateGeneral = async (data) => {
  const result = await fetchServerApi().put('/settings/global', data);
  return result.data;
}
