import React, {useEffect, useState} from 'react';
import {Empty, notification} from "antd";
import {fetchAction, updateAction} from "./actionAPI";
import {Action} from "./Action";

export const ActionEdit = ({ actionId }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchAction(actionId)
      .then(setData)
      .catch(error => {
        setData(null);
        notification.error({
          message: 'Failed to fetch data',
          description: error.message,
        })
      })
      .finally(() => setLoading(false));
  }, [actionId]);

  const onFinish = (updatedDada) => {
    setLoading(true);
    updateAction(actionId, updatedDada)
      .then((data) => {
        setData(data);
        notification.info({
          message: 'Action updated',
        });
      })
      .catch(error => {
        notification.error({
          message: 'Failed to update action',
          description: error.message,
        })
      })
      .finally(() => {
        setLoading(false);
      })
  };

  if (!data) {
    return <Empty />
  }

  return <Action data={data} onUpdate={onFinish} />
}
