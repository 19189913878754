import React, {useState} from 'react';
import {Button, Form, Input, notification, Spin} from "antd";
import './style.css';
import {loginAction} from "./loginAPI";
import {useNavigate} from "react-router";

export const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (data) => {
    setLoading(true);
    loginAction(data.username, data.password)
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        notification.error({
          message: 'Login failed',
        });
      })
      .finally(() => {
        setLoading(false);
      })
  };

  return <div className="login-page">
    <div className="login-box">
      <Spin spinning={loading} tip="loading...">
        <Form onFinish={onFinish} className="login-form">
          <Form.Item name={['username']} rules={[{ min: 1, required: true }]}>
            <Input
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item name={['password']} rules={[{ min: 1, required: true }]}>
            <Input
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  </div>
}
