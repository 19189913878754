import React, {useEffect, useState} from 'react';
import {Button, Empty, Form, Input, InputNumber, notification, Spin} from "antd";
import {fetchGeneral, updateGeneral} from "./generalAPI";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const General = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setInitLoading(true);
    fetchGeneral()
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        notification.error({
          message: 'Failed to fetch data',
          description: error.message,
        })
        setData(null)
      })
      .finally(() => {
        setInitLoading(false);
      });
  }, []);

  if (!data) {
    return <Empty />
  }

  const onFinish = (values) => {
    setInitLoading(true);
    updateGeneral(values)
      .then(setData)
      .catch((error) => {
        notification.error({
          message: 'Failed to update data',
          description: error.message,
        })
        setData(null)
      })
      .finally(() => {
        setInitLoading(false);
      });
  };

  return <>
    <Spin spinning={initLoading} tip="loading...">
      <Form
        { ...layout }
        name="nest-messages"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        initialValues={data}
      >
        <Form.Item name={['threads']} label="Number of Threads" rules={[{ type: 'number', min: 1, max: 20 }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name={['uploadSpeed']} label="Upload speed MB/s" rules={[{ type: 'number', min: 1 }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name={['apiUrl']} label="API url">
          <Input />
        </Form.Item>
        <Form.Item name={['emailNotifications']} label="Email Notifications">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  </>;
}
