import {fetchApi} from "../fetch-api";

export const fetchServers = async () => {
  const result = await fetchApi().get('/servers');
  return result.data;
}

export const fetchServer = async (serverId) => {
  const result = await fetchApi().get(`/servers/${serverId}`);
  return result.data;
}

export const deleteServer = async (serverId) => {
  await fetchApi().delete(`/servers/${serverId}`);
}

export const createServer = async (data) => {
  const result = await fetchApi().post('/servers', data);
  return result.data;
}

export const updateServer = async (serverId, data) => {
  const result = await fetchApi().put(`/servers/${serverId}`, data);
  return result.data;
}
